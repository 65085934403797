import React, {
  createContext,
  useEffect,
  useContext,
  useReducer,
  useCallback,
} from 'react';

import { isEmpty } from '../../helpers/CommonHelper';
import { apiModes } from '../../helpers/Constants';
import appActions from './actions';
import reducer from './reducer';
import { getStorage } from '../storage';
import STORAGE_KEYS from '../storageKeys';

const apiModeFromEnv = process.env.REACT_APP_API_MODE || null;

const initialState = {
  appState: {
    isInitiated: false,
    languageDirection: 'ltr',
    // TODO: its will defaultly "live", when the live api is ready.
    apiMode: apiModeFromEnv || apiModes.test,
  },
};

const App = createContext();

/**
 * Context Hook
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
export const useApp = () => useContext(App);

/**
 * App Provider
 *
 * @param {object} props - props
 * @param {object} props.children - children
 * @returns {module:JSX.Element} - JSX.Element
 */
export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // language
  const initializeAppState = useCallback(async () => {
    const appStorageLanguageDirection = await getStorage(
      STORAGE_KEYS.languageDirection
    );

    if (!isEmpty(appStorageLanguageDirection)) {
      dispatch(appActions.setLanguageDirection(appStorageLanguageDirection));
    }

    // api Mode
    const appStorageApiMode = await getStorage(STORAGE_KEYS.apiMode);

    if (!isEmpty(appStorageApiMode)) {
      dispatch(appActions.setApiModeState(appStorageApiMode));
    }

    // init
    dispatch(appActions.init());
  }, []);

  useEffect(() => {
    initializeAppState();
  }, [initializeAppState]);

  const data = {
    ...state,
    appDispatch: dispatch,
  };

  return <App.Provider value={data}>{children}</App.Provider>;
};
