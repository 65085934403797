import React, { Suspense, lazy } from 'react';

import { createStackNavigator } from '@react-navigation/stack';

import NavigationRoutes from './NavigationRoutes';
// eslint-disable-next-line import/no-extraneous-dependencies

// Screens
const Splash = lazy(() => import('../components/pages/Splash'));
const Home = lazy(() => import('../components/pages/Home'));
const ContentDetail = lazy(() => import('../components/pages/Detail'));
const TvShows = lazy(() => import('../components/pages/TvShows'));
const Movies = lazy(() => import('../components/pages/Movies'));
const Video = lazy(() => import('../components/pages/Video'));
const Mylist = lazy(() => import('../components/pages/Mylist'));
const Sports = lazy(() => import('../components/pages/Sports'));
const MoreEpisodes = lazy(() => import('../components/pages/MoreEpisodes'));
const FourKQR = lazy(() => import('../components/templates/FourKQR'));
const Live = lazy(() => import('../components/pages/Live'));
const LiveTv = lazy(() => import('../components/pages/LiveTv'));
const LongPause = lazy(() => import('../components/organisms/LongPause'));
const Profiles = lazy(() => import('../components/pages/Profiles'));
const ProfileLogin = lazy(() => import('../components/pages/ProfileLogin'));
const ViewAll = lazy(() => import('../components/pages/ViewAll'));
const ManageProfile = lazy(() => import('../components/pages/ManageProfile'));
const ModalLanguageSelection = lazy(() =>
  import('../components/pages/ModalLanguageSelection')
);
const Delete = lazy(() =>
  import('../components/pages/ManageProfile/Delete/Delete')
);
const Settings = lazy(() => import('../components/pages/Settings'));
const SignOut = lazy(() => import('../components/pages/Settings/SignOut'));
const Search = lazy(() => import('../components/pages/Search'));
const AddProfile = lazy(() =>
  import('../components/pages/ManageProfile/AddProfile')
);
const GeneralError = lazy(() => import('../components/templates/GeneralError'));
const SignIn = lazy(() => import('../components/pages/SignIn'));
const Landing = lazy(() => import('../components/pages/Landing'));
const ModalExit = lazy(() => import('../components/pages/ModalExit'));
const DevModeLogin = lazy(() => import('../components/pages/DevModeLogin'));
const DevMode = lazy(() => import('../components/pages/DevMode'));
const LiveToVod = lazy(() => import('../components/pages/LiveToVod'));
const Kids = lazy(() => import('../components/pages/Kids'));
const Trailer = lazy(() => import('../components/pages/Trailer'));

const Stack = createStackNavigator();

/**
 * Transition config
 */
const config = {
  animation: 'spring',
  config: {
    stiffness: 1000,
    damping: 500,
    mass: 3,
    overshootClamping: true,
    restDisplacementThreshold: 0.01,
    restSpeedThreshold: 0.01,
  },
};

/**
 * NavigationOptions for transparent Stack.Screen
 */
const navigationOptions = {
  cardStyle: { backgroundColor: 'transparent' },
  headerShown: false,
  transitionSpec: {
    open: config,
    close: config,
  },
};

/**
 * Create react navigation routers
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
const AppNavigator = () => {
  return (
    <Suspense>
      <Stack.Navigator
        initialRouteName={NavigationRoutes.splash}
        screenOptions={{
          freezeOnBlur: true,
          presentation: 'transparentModal',
          animationEnabled: false,
        }}>
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.signIn}
          component={SignIn}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.generalError}
          component={GeneralError}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.landing}
          component={Landing}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.fourk}
          component={FourKQR}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.splash}
          component={Splash}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.home}
          component={Home}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.sports}
          component={Sports}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.movies}
          component={Movies}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.mylist}
          component={Mylist}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.tvshows}
          component={TvShows}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.contentdetail}
          component={ContentDetail}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.video}
          component={Video}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.moreEpisodes}
          component={MoreEpisodes}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.live}
          component={Live}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.livetv}
          component={LiveTv}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.longPause}
          component={LongPause}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.profiles}
          component={Profiles}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.profilelogin}
          component={ProfileLogin}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.viewAll}
          component={ViewAll}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.manageprofile}
          component={ManageProfile}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.addprofile}
          component={AddProfile}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.modalLanguageSelection}
          component={ModalLanguageSelection}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.delete}
          component={Delete}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.settings}
          component={Settings}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.signOut}
          component={SignOut}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.search}
          component={Search}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.liveToVod}
          component={LiveToVod}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.exit}
          component={ModalExit}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.kids}
          component={Kids}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.devModeLogin}
          component={DevModeLogin}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.devMode}
          component={DevMode}
        />
        <Stack.Screen
          options={navigationOptions}
          name={NavigationRoutes.trailer}
          component={Trailer}
        />
      </Stack.Navigator>
    </Suspense>
  );
};

export default AppNavigator;
