const AbstractKeys = {
  LEFT: 'left',
  RIGHT: 'right',
  UP: 'up',
  DOWN: 'down',
  ENTER_PRESS: 'enterPress',
  PLAY: 'play',
  PAUSE: 'pause',
  PLAY_PAUSE: 'playPause',
  FAST_FORWARD: 'fastForward',
  REWIND: 'rewind',
  STOP: 'stop',
  SWIPE_RIGHT: 'swipeRight',
  SWIPE_LEFT: 'swipeLeft',
  SWIPE_UP: 'swipeUp',
  SWIPE_DOWN: 'swipeDown',
  LONG_RIGHT: 'longRight',
  LONG_LEFT: 'longLeft',
  LONG_DOWN: 'longDown',
  LONG_UP: 'longUp',
  LONG_ENTER_PRESS: 'longSelect',
  BACK: 'back',
};

export default AbstractKeys;
