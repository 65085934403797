import {
  convivaAppTracker,
  setUserId,
  trackPageView,
  trackCustomEvent,
} from '@convivainc/conviva-js-appanalytics';
import { APPLICATION_VERSION } from 'shared/src/libs/version/config';
import * as Conviva from '@convivainc/conviva-js-coresdk';
import env from 'shared/src/middleware/services/config/';

var videoAnalytics;

/**
 * Create conviva tracker
 *
 * @returns {void} - void
 */
export const createConvivaTracker = () => {
  convivaAppTracker({
    appId: env.conviva.appId,
    convivaCustomerKey: env.conviva.customerKey,
    appVersion: APPLICATION_VERSION,
    platform: 'web',
  });
};

/**
 * Create conviva video
 *
 * @returns {void} - void
 */
export const createVideoTracker = () => {
  if (process.env.NODE_ENV === 'development') {
    var settings = {};

    settings[
      Conviva.Constants.GATEWAY_URL
    ] = `https://${env.conviva.customerKey}.ts-testonly.conviva.com`;

    settings[Conviva.Constants.LOG_LEVEL] = Conviva.Constants.LogLevel.NONE;
    Conviva.Analytics.init(env.conviva.customerKey, null, settings);

    videoAnalytics = Conviva.Analytics.buildVideoAnalytics();

    videoAnalytics.reportPlaybackRequested();
  } else {
    // production release
    // Conviva.Analytics.init(env.conviva.customerKey, null);
  }
};

/**
 * Set conviva user
 *
 * @param {string} userId - userId
 */
export const setConvivaUser = (userId) => {
  setUserId(userId);
};

/**
 * Set conviva page
 *
 * @param {string} page - page
 */
export const setConvivaPage = (page) => {
  trackPageView(page);
};

/**
 *  StartConviva
 *
 * @param {object} player - player
 */
export const setConvivaPlayer = (player) => {
  if (!videoAnalytics) return;

  videoAnalytics.setPlayer(player);
};

/**
 * Set conviva page
 *
 * @param {object} eventparams - eventparams
 */
export const setConvivaCustomEvent = (eventparams) => {
  if (!eventparams?.eventName) return;

  trackCustomEvent({
    name: eventparams.eventName,
    data: eventparams,
  });
};

/**
 *  PostConviva
 *
 * @param {object} contentInfo - contentInfo
 */
export const setConvivaContent = (contentInfo) => {
  if (!videoAnalytics) return;

  var content = {};

  content['c3.cm.contentType'] = contentInfo.contentStatusType;
  content[Conviva.Constants.DURATION] = contentInfo.duration;
  content[Conviva.Constants.ASSET_NAME] = contentInfo.title;
  content['c3.app.version'] = APPLICATION_VERSION;

  videoAnalytics.setContentInfo(content);
};

/**
 *  StopConviva
 *
 */
export const releaseConvivaPlayer = () => {
  if (!videoAnalytics) return;

  videoAnalytics.reportPlaybackEnded();
  videoAnalytics.release();
  Conviva.Analytics.release();
};
