import TYPES from './types';

/**
 * Global Focus Reducer
 *
 * @param {object} state - state
 * @param {object} action - action
 * @returns {object} - reducer object
 */
const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.SET_GLOBAL_FOCUS:
      return {
        ...state,
        globalFocus: action.payload,
      };

    default:
      throw new Error();
  }
};

export default reducer;
