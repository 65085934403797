import React, { createContext, useContext, useReducer } from 'react';
import reducer from './reducer';

const initialState = {
  globalFocus: {},
};

const GlobalFocus = createContext();

/**
 * Context Hook
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
export const useGlobalFocus = () => useContext(GlobalFocus);

/**
 * Global Focus Provider
 *
 * @param {object} props - props
 * @param {object} props.children - children
 * @returns {module:JSX.Element} - JSX.Element
 */
export const GlobalFocusProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const data = {
    ...state,
    globalFocusDispatch: dispatch,
  };

  return <GlobalFocus.Provider value={data}>{children}</GlobalFocus.Provider>;
};
