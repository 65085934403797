import React, { useEffect } from 'react';
import {
  DeviceEventEmitter,
  NativeModules,
  NativeEventEmitter,
  Platform,
} from 'react-native';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { initNavigation } from '@digiturk/react-spatial-navigation';

import { pressedKeyEventHandler } from '../helpers/FocusHelper';
import { navigationRef } from '../middleware/services/navigationForService';
import HealthCheck from '../components/pages/HealthCheck';

import NavigationRoutes from './NavigationRoutes';
import AppNavigator from './AppNavigator';
import PropList from './proptypes';
import { createConvivaTracker } from '../../../../tools/conviva/';

initNavigation({
  debug: false,
  visualDebug: false,
});

let KeyHandlerEventEmitter = null;

try {
  // eslint-disable-next-line no-unused-vars
  KeyHandlerEventEmitter = new NativeEventEmitter(
    NativeModules.KeyHandlerModule
  );
} catch (e) {}

const navTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: 'transparent',
  },
};

/**
 * switch to other stack navigator when screen is a video one
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
export const Routes = () => {
  useEffect(() => {
    if (Platform.OS === 'windows') {
      // TODO: change DeviceEventEmitter to KeyHandlerEventEmitter
      DeviceEventEmitter.addListener(
        'PressedKeyCustom',
        pressedKeyEventHandler
      );
    } else if (Platform.OS === 'web') {
      // catch key and call pressed key event handler method
      window.addEventListener('keydown', (event) => {
        if (event.repeat === true) {
          pressedKeyEventHandler(event);
        } else {
          window.addEventListener('keyup', pressedKeyEventHandler);
        }
      });
    } else {
      // ???
    }

    return () => {
      if (Platform.OS === 'windows') {
        DeviceEventEmitter.removeListener(
          'PressedKeyCustom',
          pressedKeyEventHandler
        );
      } else if (Platform.OS === 'web') {
        // catch key and call pressed key event handler method
        window.addEventListener('keydown', (event) => {
          if (event.repeat === true) {
            pressedKeyEventHandler(event);
          } else {
            window.addEventListener('keyup', pressedKeyEventHandler);
          }
        });
      } else {
        // ???
      }
    };
  }, []);

  useEffect(() => {
    createConvivaTracker();
  }, []);

  return (
    <Router>
      <Switch>
        <Route path={NavigationRoutes.healthcheck} component={HealthCheck} />
        <Route
          path="/"
          component={() => (
            <NavigationContainer theme={navTheme} ref={navigationRef}>
              <AppNavigator />
            </NavigationContainer>
          )}
        />
      </Switch>
    </Router>
  );
};

Routes.propTypes = PropList;

export default Routes;
