import { Platform } from 'react-native';
import {
  GetScaledValue,
  GetWindowHeight,
  GetWindowWidth,
} from '@digiturk/screen-size';
import RNRestart from 'react-native-restart';
import {
  CompetitionStatus,
  FONT_FAMILY,
  SPACING_VALUES,
} from '../helpers/Enums';
import urlDecode from '../libs/urlDecode';
import RNExitApp from 'react-native-exit-app';

export const sidebarWidth = GetScaledValue(200);
export const borderWidth = GetScaledValue(6);
export const borderRadius = GetScaledValue(SPACING_VALUES.SBIG);
export const itemMargin = GetScaledValue(SPACING_VALUES.SLG);
export const marginEnd = GetScaledValue(68);
export const padding = GetScaledValue(SPACING_VALUES.SSM);
export const titleArea = GetScaledValue(84);
export const titleHeight = GetScaledValue(60);
export const titleSpace = titleArea - titleHeight;
export const isWeb = Platform.OS === 'web';
export const marginBottom = GetScaledValue(30);
export const paddingBottom = GetScaledValue(SPACING_VALUES.SLG);
export const windowWidth = GetWindowWidth();
export const windowHeight = GetWindowHeight();
export const isAnimationsDisabled = isWeb;
export const SEEK_VALUE = 10;
export const railItemBorderGap = 20;
export const posterPadding = GetScaledValue(10);
export const railInitialData = {
  items: [{}, {}, {}, {}, {}, {}, {}, {}],
};

//INFO: We have to find device model
//INFO: https://confluence.digiturk.com.tr/pages/viewpage.action?spaceKey=TOD2&title=Client-Middleware+Comminucation
export const selectPlatform = Platform.select({
  ios: 'appletv',
  android: 'androidtv',
  web: 'webtv',
});

/**
 * CalculateTimeLeft
 * <p>Calculates remaining time until given date </p>
 *
 * @param {string} startDate - givenDate
 * @returns {object} - returns date as days hours minutes and seconds
 */
export const calculateTimeLeft = (startDate) => {
  const now = new Date().getTime();
  const targetDate = new Date(startDate).getTime();
  const timeDifference = targetDate - now;

  if (timeDifference <= 0) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }

  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
};

/**
 * Exit from the application method
 */
export const exitApp = () => {
  if (Platform.OS === 'android' || Platform.OS === 'ios') {
    RNExitApp.exitApp();
  }

  if (Platform.OS === 'web') {
    // TODO: check if this works properly on Smart TVs
    window.close();
  }
};

/**
 * Reload application method
 */
export const reloadApp = () => {
  if (Platform.OS === 'web') {
    window.location.reload(false);
  } else {
    RNRestart.Restart();
  }
};

/**
 * replace item index with value 1 for an id
 *
 * @param {string} value - value
 * @returns {string} - updated id
 */
export const resetId = (value) => {
  return value.replace(/[0-9]+(?!.*[0-9])/, function (match) {
    return 0;
  });
};

/**
 * get id from an indexed value
 *
 * @param {string} value - value
 * @returns {string} - id
 */
export const getId = (value) => {
  return value.match(/[0-9]+(?!.*[0-9])/)?.[0];
};

/**
 *
 * @param {string} hexCode - color
 * @param {string} factor - rate of luminance
 * @returns {any} -
 */
export const adjustColor = (hexCode, factor) => {
  if (!hexCode) return;
  // Hex kodunu R, G, B bileşenlerine ayırma
  var r = parseInt(hexCode.slice(1, 3), 16);
  var g = parseInt(hexCode.slice(3, 5), 16);
  var b = parseInt(hexCode.slice(5, 7), 16);

  // Koyulaştırma veya aydınlatma faktörü uygulama
  r = Math.round(r * factor);
  g = Math.round(g * factor);
  b = Math.round(b * factor);

  // Değerleri sınırlama (0-255 aralığına)
  r = Math.min(Math.max(r, 0), 255);
  g = Math.min(Math.max(g, 0), 255);
  b = Math.min(Math.max(b, 0), 255);

  // Yeni renk kodunu oluşturma
  var adjustedHexCode =
    '#' + ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');

  return adjustedHexCode;
};

/**
 * @param {string|number} iconPath - hex or uri
 * @returns {object} -
 */
export const handleDecideColorOrImage = (iconPath) => {
  if (iconPath === '' || !iconPath) return { color: '#ffbc00' };
  else if (iconPath.length === 6) {
    return isHex(iconPath) ? { color: '#' + iconPath } : { color: '#ffbc00' };
  } else if (iconPath[0] === '#') return { color: iconPath };
  else return { iconPath };
};

/**
 *
 * @param {string} string -
 * @returns {boolean} - isHex
 */
export const isHex = (string) => {
  for (var i = 0; i < string.length; i++) {
    var char = string[i];

    if (
      !(
        (char >= '0' && char <= '9') ||
        (char >= 'A' && char <= 'F') ||
        (char >= 'a' && char <= 'f')
      )
    ) {
      return false;
    }
  }

  return true;
};

/**
 * Create Key-Value object from Array
 *
 * @param {Array} data - array
 * @returns {object} - object
 */
export const getAssignArrayToObject = (data) => {
  return Object.getOwnPropertyNames(data).reduce(
    (a, v) => ({ ...a, [v]: v }),
    {}
  );
};

/**
 *
 * @param {number} itemLength - length of list
 * @param {number} itemWidth - width of item
 * @param {number|boolean} railWidth - width of rail
 * @returns {number} - breakpoint of scrollToIndex
 */
export const getBreakPoint = (itemLength, itemWidth, railWidth) => {
  const width = (railWidth ? railWidth : windowWidth) - sidebarWidth;

  const viewableItemCount = width / itemWidth;
  const integer = Math.floor(viewableItemCount);
  const decimal = viewableItemCount % 1;

  const breakpoint = itemLength - integer;

  return { breakpoint: breakpoint <= 0 ? 0 : breakpoint, decimal };
};

/**
 * Get Rail Item Configurations
 *
 * @param {string} railType - rail type
 * @returns {object} - width height object
 */
export const getRailItemConfigurations = (railType) => {
  const defaultWidth = 240;
  const defaultHeight = 320;
  const defaultHeroHeight = 630;
  const defaultRailTop = 530;

  const rails = {
    slider: {
      width: 380,
      height: 126,
      heroWidth: 1280,
      heroHeight: 720,
      railTop: 630,
      title: false,
      poster: 'hero-card',
    },
    '1x2_tall_tray': {
      oldName: 'BoxSets',
      width: 170,
      height: 340,
      heroHeight: 630,
      heroWidth: 1120,
      railTop: 530,
      poster: 'tall-image',
    },
    '1x1_square_tray': {
      oldName: 'Competitions',
      width: 240,
      height: 240,
      heroWidth: 1920,
      heroHeight: 720,
      railTop: 635,
      poster: 'square-tile',
    },
    '4x3_tray': {
      oldName: 'Sports',
      width: 380,
      height: 285,
      heroWidth: 1920,
      heroHeight: 720,
      railTop: 530,
      poster: 'tile-block',
    },
    EndOfMovie: {
      width: 576,
      height: 324,
      heroHeight: 200,
      heroWidth: 600,
      railTop: 530,
      poster: 'tall-image',
    },
    '2x1_tray': {
      oldName: 'TrendingOnTod',
      width: 520,
      height: 260,
      heroHeight: 630,
      heroWidth: 1120,
      railTop: 530,
      poster: 'wide',
    },
    '2x3_poster_tray': {
      oldName: 'NewOnTod',
      width: 240,
      height: 360,
      heroHeight: 630,
      heroWidth: 1120,
      railTop: 530,
      poster: 'poster',
    },
    '2x3_head2head_team_logo_tray': {
      oldName: 'DontMissOut',
      width: 240,
      height: 360,
      heroHeight: 630,
      heroWidth: 1120,
      railTop: 530,
      poster: 'poster',
    },
    '16x9_continue_watch': {
      width: 380,
      height: 277,
      heroHeight: 720,
      heroWidth: 1280,
      railTop: 618,
      poster: 'tile',
    },
    '16x9_top10_tray_v1': {
      oldName: 'TopTenMoviesByGenreWithRegion',
      width: 380,
      height: 213,
      position: { top: GetScaledValue(120) },
      heroHeight: 630,
      heroWidth: 1280,
      railTop: 530,
      poster: 'tile',
    },
    '2x3_top_ten_tray': {
      oldName: 'TopTenMoviesByRegionForToday',
      width: 380,
      height: 214,
      position: { start: GetScaledValue(140) },
      heroHeight: 630,
      heroWidth: 1120,
      railTop: 630,
      poster: 'tile',
    },
    '16x9_top10_tray_v2': {
      oldName: 'TopTenMoviesByCountryForToday',
      width: 240,
      height: 360,
      position: { start: GetScaledValue(140) },
      heroHeight: 630,
      heroWidth: 1120,
      railTop: 530,
      poster: 'poster',
    },
    '1x2_tall_tray_with_logo': {
      oldName: 'ComingSoon',
      width: 380,
      height: 760,
      heroHeight: 0,
      railTop: 60,
      expandedWidth: 1240,
      poster: 'tall-image',
      wallpaper: 'tile-hover-expand-image',
    },
    '16x9_tray_branded_background': {
      oldName: 'MovieCollection',
      heroHeight: isWeb ? 720 : 0,
      heroWidth: 1920,
      railTop: isWeb ? 530 : 0,
      width: 380,
      height: 214,
      position: { top: isWeb ? 0 : titleHeight },
      poster: 'tile',
      hero: 'branded-image-8x3',
    },
    '2x3_poster_tray_branded': {
      oldName: 'MovieStarCollection',
      heroHeight: isWeb ? 720 : 0,
      heroWidth: 1920,
      railTop: isWeb ? 530 : 0,
      width: 240,
      height: 360,
      position: { top: isWeb ? 0 : titleHeight },
      poster: 'poster',
      hero: 'branded-image-8x3',
    },
    '4x3_branded_background_tray': {
      oldName: 'SerieCollection',
      heroHeight: isWeb ? 720 : 0,
      heroWidth: 1920,
      railTop: isWeb ? 600 : 0,
      width: 380,
      height: 285,
      position: { top: isWeb ? 0 : titleHeight },
      poster: 'tile-block',
      hero: 'branded-image-8x3',
    },
    '1x1_square_tray_branded': {
      oldName: 'CompetitionCollection',
      heroHeight: isWeb ? 720 : 0,
      heroWidth: 1920,
      railTop: isWeb ? 600 : 0,
      width: 240,
      height: 240,
      position: { top: isWeb ? 0 : titleHeight },
      poster: 'square-tile',
      hero: 'branded-image-8x3',
    },
    my_list: {
      width: 240,
      height: 360,
      heroHeight: 630,
      heroWidth: 1120,
      railTop: 580,
      poster: 'poster',
    },
    '16x9_thumbnail': {
      oldName: 'SuperHeros',
      width: 380,
      height: 214,
      heroHeight: 630,
      heroWidth: 1120,
      railTop: 530,
      poster: 'tile',
    },
    '1x1_two_row_rail': {
      oldName: 'DoubleRow',
      width: 240,
      height: 240,
      heroHeight: 720,
      heroWidth: 1920,
      railTop: 380,
    },
    whats_on_now: {
      width: 520,
      height: 376,
      heroHeight: 630,
      railTop: 530,
      poster: 'wallpaper',
    },
    '16x9_head2head_custom_sports_tray_v1': {
      oldName: 'LiveAndUpcoming',
      poster: 'sports-head2head-background',
      width: 520,
      height: 318,
      heroHeight: 720,
      heroWidth: 1280,
      railTop: 610,
    },
    '16x9head2head_custom_sports_tray_v3': {
      oldName: 'Tournaments',
      width: 520,
      height: 264,
      heroHeight: 630,
      heroWidth: 1120,
      railTop: 530,
    },
    '16x9_head2head_custom_sports_tray_v2': {
      oldName: 'Football',
      width: 520,
      height: 324,
      heroHeight: 630,
      heroWidth: 1120,
      railTop: 530,
    },
    '16x9_head2head_custom_sports_tray_v6': {
      oldName: 'Tod360',
      width: 520,
      height: 324,
      heroHeight: 630,
      heroWidth: 1120,
      railTop: 530,
    },
    '16x9_spotlight_tray': {
      oldName: 'Showcase',
      width: 380,
      height: 160,
      position: {
        top: GetScaledValue(53),
        zIndex: 0,
      },
      heroWidth: 1920,
      heroHeight: 720,
      railTop: 635,
      title: false,
      poster: 'showcase-image',
    },
    content_film_detail: {
      heroWidth: 1600,
      heroHeight: 900,
    },
    content_series_detail: {
      heroWidth: 1600,
      heroHeight: 900,
    },
    DetailImage: {
      heroWidth: 384,
      heroHeight: 216,
    },
    sport_detail_hero: {
      heroWidth: 1600,
      heroHeight: 900,
    },
    tournament_hero: {
      heroWidth: 1600,
      heroHeight: 900,
    },
    season: {
      width: 395,
      height: 94,
      railTop: 282,
    },
    episode: {
      width: 380,
      height: 214,
      railTop: 436,
      poster: 'thumbnail',
    },
    GroupCard: {
      width: 1080,
      height: 416,
    },
    search_history: {
      width: 240,
      height: 360,
      heroHeight: 0,
      railTop: 0,
      poster: 'poster',
    },
    SearchSports: {
      width: 180,
      height: 270,
      heroHeight: 0,
      railTop: 0,
    },
    SearchTop: {
      width: 180,
      height: 270,
      heroHeight: 0,
      railTop: 0,
    },
    SearchMovies: {
      width: 180,
      height: 270,
      heroHeight: 0,
      railTop: 0,
    },
    SearchTvShows: {
      width: 180,
      height: 270,
      heroHeight: 0,
      railTop: 0,
    },
    trailers: {
      width: 360,
      height: 194,
      poster: 'wallpaper',
    },
    viewing_option: {
      width: 520,
      height: 293,
      poster: 'wallpaper',
    },
    '16x9_live_channels_tray': {
      oldName: 'AlsoLiveNow',
      width: 520,
      height: 376,
      railTop: 530,
      poster: 'wallpaper',
    },
    '1x1_square_tray_channels': {
      oldName: 'Channels',
      width: 280,
      height: 280,
      heroHeight: 0,
      heroWidth: 0,
      railTop: 50,
      poster: 'square-tile',
    },
    // mixed sports
  };

  const getWidth = rails[railType] ? rails[railType].width : defaultWidth;
  const getHeight = rails[railType] ? rails[railType].height : defaultHeight;
  const getPosition = rails[railType] ? rails[railType].position : {};
  const getHeroHeight = rails[railType]
    ? rails[railType].heroHeight
    : defaultHeroHeight;
  const getHeroWidth = rails[railType]
    ? rails[railType]?.heroWidth
      ? rails[railType]?.heroWidth
      : rails[railType]?.heroHeight * (16 / 9)
    : 1920;
  const getRailTop = rails[railType] ? rails[railType].railTop : defaultRailTop;
  const getTitle = rails[railType]?.title ?? true;
  const getExpandedWidth = rails[railType]?.expandedWidth ?? 0;

  return {
    width: getWidth,
    height: getHeight,
    position: getPosition,
    heroHeight: getHeroHeight,
    heroWidth: getHeroWidth,
    railTop: getRailTop,
    hasTitle: getTitle,
    poster: rails[railType]?.poster,
    hero: rails[railType]?.hero,
    expandedWidth: getExpandedWidth,
  };
};

/**
 * Check if the rail type is out of scope
 *
 * @param {string} railType - The rail type to check
 * @returns {boolean} - Returns true if the rail type is out of scope
 */
export const outOfScopes = (railType) => {
  const railTypes = ['whats_on_now'];

  return !railTypes.includes(railType);
};

/**
 * Check if the section is full screen
 *
 * @param {string} sectionType - section type
 * @returns {boolean} - return is full screen
 */
export const isFullScreenSection = (sectionType) => {
  const fullScreenSections = [
    'more_like_this',
    'content_series_seasons_detail',
    'sport_detail_hero',
    'tournament_hero',
    'content_series_detail',
    'content_film_detail',
    'trailers',
    '16x9head2head_custom_sports_tray_v3',
  ];

  return fullScreenSections.includes(sectionType);
};

/**
 * Check if the rail type is valid
 *
 * @param {string} railType - The rail type to check
 * @returns {boolean} - Returns true if the rail type is valid
 */
export const isValidRailType = (railType) => {
  const validRailTypes = [
    'more_like_this',
    'content_series_detail',
    'content_film_detail',
    'content_series_seasons_detail',
    'sport_detail_hero',
    'tournament_hero',
    'live_standings',
    'head2head',
    'lineups',
    'live_stats',
    'Stats',
    'KeyPlayers',
    'substitutes',
    'match_stats',
    '16x9head2head_custom_sports_tray_v3',
    'tournament_ranking',
    'live_sports_on_now',
    '16x9_head2head_custom_sports_tray_v2',
    '16x9_head2head_custom_sports_tray_v6',
    '2x3_poster_tray',
    '1x1_square_tray',
    'trailers',
    '16x9_live_channels_tray',
    '16x9_head2head_custom_sports_tray_v1',
    'whats_on_now',
    // 'viewing_option', // Showing in Hero
  ];

  return validRailTypes.includes(railType);
};

/**
 * Get Organize Text Styles
 *
 * @param {object} originalStyle - style
 * @returns {object} - edited style
 */
export const getOrganizeStyles = (originalStyle) => {
  // A copy was created for the object cannot be extended error
  const style = { ...originalStyle };

  // NOTE: shouldn't set "fontWeight" for Android, changed fontType
  delete style?.fontFamily;

  switch (style?.fontWeight) {
    case 100:
      style.fontFamily = FONT_FAMILY.THIN;
      break;
    case 200:
      style.fontFamily = FONT_FAMILY.EXTRA_LIGHT;
      break;
    case 300:
      style.fontFamily = FONT_FAMILY.LIGHT;
      break;
    case 400:
      style.fontFamily = FONT_FAMILY.REGULAR;
      break;
    case 500:
      style.fontFamily = FONT_FAMILY.MEDIUM;
      break;
    case 600:
      style.fontFamily = FONT_FAMILY.SEMIBOLD;
      break;
    case 700:
      style.fontFamily = FONT_FAMILY.BOLD;
      break;
    case 800:
      style.fontFamily = FONT_FAMILY.EXTRA_BOLD;
      break;
    case 900:
      style.fontFamily = FONT_FAMILY.BLACK;
      break;
    default:
      style.fontFamily = FONT_FAMILY.BLACK;
      break;
  }

  delete style.fontWeight;

  return style;
};

/**
 * Converts values for right-to-left mode
 *
 * @param {number} data - Value to be scaled
 * @returns {number} - Scaled value
 */
export const calculateForRTL = (data) => {
  return data * -1;
};

/**
 * Get links from the response based on the specified criteria.
 *
 * @param {object} response - The response object.
 * @param {string} rel - The rel value to match.
 * @param {string} key - The key to filter the response by.
 * @param {string} value - The value to match for the specified key.
 * @returns {string} - The URL matching the specified criteria.
 */
export const getLink = (response, rel, key, value) => {
  try {
    if (!key) {
      const { href: url, method } = response.links.find(
        (item) => item.rel === rel
      );

      return { url, method };
    }

    const filteredItem = response.find((item) => item[key] === value);
    const { href: url, method } = filteredItem.links.find(
      (item) => item.rel === rel
    );

    return { url, method };
  } catch (error) {
    // unless it can't find anything
    return false;
  }
};

/**
 * Get links from the response based on the specified criteria or only for given object.
 *
 * @param {object} response - The response object.
 * @param {string} key - The key to filter the response by.
 * @param {string} value - The value to match for the specified key.
 * @returns {Array} - The URLs matching the specified criteria.
 */
export const getRelLinks = (response, key, value) => {
  const filteredItem = key
    ? response.find((item) => item[key] === value)
    : response;

  const links = filteredItem.links.map((item) => {
    return { [item.rel]: item.href };
  });

  return links;
};

/**
 * Build Image Link
 *
 * @param {object|string} urlObj - The URL object or string.
 * @param {number} width - The width of the image.
 * @param {number} height - The height of the image.
 * @returns {string} - The URL of the image.
 */
export const buildImageLink = (urlObj, width, height) => {
  const fullName =
    Platform.OS === 'ios'
      ? urlObj?.fullName
      : urlObj?.fullName?.replace(/\.(png|jpe?g)$/i, '.webp');

  return urlDecode(
    `${urlObj.imageResizerBaseUrl}/resources/images/link/${urlObj.id}/${urlObj.mediaSettingsId}/${urlObj.timeStamp}/${urlObj.x1}:${urlObj.y1}:${urlObj.x2}:${urlObj.y2}/${width}*${height}/${fullName}`
  );
};

/**
 *
 * @param {data} data - The item object.
 * @returns {object} - The URL of the first image matching the specified criteria.
 */
export const updateDataWithImage = (data) => {
  const updatedData = {
    ...data,
    image: {
      hero_16x9:
        data.hero_5x2 || data.hero_8x2 || data.hero_8x3 || data.hero_3x1,
    },
  };

  return updatedData;
};

let trailerObject = null;

/**
 * Trailer object
 *
 * @returns {object} - get and set method
 */
export const trailer = {
  get: () => trailerObject,
  set: (data) => {
    trailerObject = data;
  },
};

/**
 * Generate a random number between the specified minimum and maximum values.
 *
 * @param {number} min - The minimum value.
 * @param {number} max - The maximum value.
 * @returns {number} - The generated random number.
 */
export const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

/**
 * Get Rail Images
 *
 * @param {object} images - images
 * @param {string} railType -railType
 * @param {boolean} logo - logo key
 * @returns {object} - image
 */
export const getRailImages = (images, railType, logo) => {
  if (isEmpty(images)) return { hero: '', poster: '', logo: '' };

  if (!Object.values(images)?.[0]?.isResizable) {
    const mediaUrl = Object.values(images)?.[0]?.noResizeImageUrl ?? '';

    return { poster: urlDecode(mediaUrl) };
  }

  const { width, height, heroWidth, heroHeight, poster, hero } =
    getRailItemConfigurations(railType);

  const obj = {};
  const target = {
    poster,
    hero: hero ?? 'wallpaper',
    width: GetScaledValue(width),
    height: GetScaledValue(height),
    heroWidth: GetScaledValue(heroWidth),
    heroHeight: GetScaledValue(heroHeight),
    logo: logo ?? null,
  };

  if (railType === '1x2_tall_tray_with_logo') {
    target.width = GetScaledValue(380);
    target.height = GetScaledValue(760);
    target.heroHeight = GetScaledValue(760);
    target.heroWidth = GetScaledValue(1240);
    target.hero = 'tile-hover-expand-image';
  }

  if (images[target.hero]) {
    obj.hero = buildImageLink(
      images[target.hero],
      target.heroWidth,
      target.heroHeight
    );
  }

  if (images[target.poster]) {
    obj.poster = buildImageLink(
      images[target.poster],
      target.width,
      target.height
    );
  }

  if (!!logo && images[target.logo]) {
    if (!images[target.logo]?.isResizable) {
      const mediaUrl = images[target.logo].noResizeImageUrl ?? '';

      return { logo: urlDecode(mediaUrl) };
    }

    obj.logo = buildImageLink(
      images[target.logo],
      GetScaledValue(480),
      GetScaledValue(160)
    );
  }

  return obj;
};

/**
 * Throttle function
 *
 * @param {Function} triggerFunction - trigger function
 * @param {number} limit - millisecond limit
 * @returns {Function} - throttled function
 */
export const throttle = (triggerFunction, limit) => {
  let lastRan;
  let lastFunc;

  return (...args) => {
    const context = this;
    const now = Date.now();

    if (!lastRan || now - lastRan >= limit) {
      triggerFunction.apply(context, args);
      lastRan = now;
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(() => {
        triggerFunction.apply(context, args);
        lastRan = Date.now();
      }, limit - (now - lastRan));
    }
  };
};

/**
 * unique Array By Field
 *
 * @param {Array} array - array
 * @param {string} field - field
 * @returns {Array} - unique array
 */
export const uniqueByField = (array, field) => {
  return array.filter(
    (item, index, self) =>
      index === self?.findIndex((t) => t[field] === item[field])
  );
};

/**
 * Convert seconds to hours and minutes
 *
 * @param {number} seconds - The number of seconds
 * @returns {string} - The formatted time as hours and minutes
 */
export const convertToMinutes = (seconds) => {
  if (!seconds) return 0;

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  return `${hours}h ${minutes}m`;
};

/**
 * Convert seconds to hours and minutes
 *
 * @param {number} seconds - The number of seconds
 * @returns {string} - The formatted time as hours and minutes
 */
export const convertSecondsToHours = (seconds) => {
  if (!seconds) return 0;

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  return `${hours}h ${minutes}m`;
};

/**
 * Converts seconds to minutes and seconds format
 *
 * @param {number} duration - duration in seconds
 * @returns {string} - duration in minutes and seconds format
 */
export const convertSecondToMinute = (duration) => {
  if (!duration) return 0;
  const minutes = Math.floor(duration / 60);
  const seconds = duration % 60;

  return `${minutes}m ${seconds}s`;
};

/**
 * Get Match and Program Status
 *
 * @param {object} competition - competition
 * @returns {object} - match and program status
 */
export const getMatchAndProgramStatus = (competition) => {
  const now = new Date().getTime();

  const matchDate = new Date(competition.matchDate).getTime();
  const programStartTime = new Date(competition.programStartTime).getTime();
  const programEndTime = competition.programEndTime
    ? new Date(competition.programEndTime).getTime()
    : null;

  let matchStatus = null;
  let programStatus = null;

  if (now < programStartTime) {
    programStatus = CompetitionStatus.UPCOMING;
    matchStatus = CompetitionStatus.UPCOMING;
  } else if (now > programStartTime && now < programEndTime) {
    programStatus = CompetitionStatus.LIVE;

    if (now > matchDate) {
      matchStatus = CompetitionStatus.LIVE;
    } else {
      matchStatus = CompetitionStatus.UPCOMING;
    }
  } else {
    programStatus = CompetitionStatus.PAST;
    matchStatus = CompetitionStatus.PAST;
  }

  return {
    matchStatus,
    programStatus,
    ...competition,
  };
};

/**
 * isEmpty Method
 *
 * @param {object} value - value
 * @returns {boolean}  isEmpty - isEmpty
 */
export const isEmpty = (value) => {
  if (value === undefined) {
    return true;
  }

  if (
    typeof value === 'function' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    Object.prototype.toString.call(value) === '[object Date]'
  ) {
    return false;
  }

  if (value == null || (Array.isArray(value) && value.length === 0)) {
    return true;
  }

  if (typeof value === 'object') {
    return Object.keys(value).length === 0;
  }

  if (typeof value === 'string') {
    return value.length === 0;
  }

  return false;
};

/**
 * Get is rail releated to HeroBasic
 *
 * @param {string} railType - railType
 * @returns {boolean} - is rail releated to HeroBasic
 */
export const isRailHeroBasic = (railType) => {
  const heroBasicRails = [
    '16x9_spotlight_tray',
    '1x1_square_tray',
    '4x3_tray',
    'whats_on_now',
    '16x9_tray_branded_background',
    '4x3_branded_background_tray',
    '1x1_square_tray_branded',
    '2x3_poster_tray_branded',
    '1x1_two_row_rail',
  ];

  return heroBasicRails.includes(railType);
};

/**
 * Seeks the selected time
 *
 * @param {string} eventTimeStamp - The timestamp of the event to seek to.
 * @param {string} programStartTime - The timestamp of the program start.
 * @param {object} player - The Bitmovin Player instance.
 * @param {string} matchStartTimeStamp - The timestamp of the match start.
 * @param {string} matchEndTimeStamp - The timestamp of the match end.
 * @returns {void}
 */
export const seekToEventTime = async (
  eventTimeStamp,
  programStartTime,
  player,
  matchStartTimeStamp,
  matchEndTimeStamp
) => {
  if (!eventTimeStamp || !programStartTime) {
    console.error(
      'Missing parameters: eventTimeStamp and/or programStartTime.'
    );

    return;
  }

  const eventTime = new Date(eventTimeStamp).getTime();
  const programStart = new Date(programStartTime).getTime();
  const isLive = await player?.current.isLive();

  if (isLive) {
    const maxDvrDuration = await player?.current.getMaxTimeShift();
    const timeDifferenceInSeconds = (eventTime - programStart) / 1000;
    const seekableTimeInSeconds = maxDvrDuration + timeDifferenceInSeconds;

    if (isNaN(seekableTimeInSeconds)) {
      console.error(
        'Calculated seekable time is NaN. Skipping seek operation.'
      );

      return;
    }

    try {
      player?.current.timeShift(seekableTimeInSeconds);
      console.info(
        `Player seeked to: ${seekableTimeInSeconds} seconds relative to live (DVR adjusted).`
      );
    } catch (error) {
      console.error('Error while time-shifting:', error);
    }

    return;
  }

  const totalDuration = await player?.current.getDuration();
  const matchStartTime = new Date(matchStartTimeStamp).getTime();
  const matchEndTime = new Date(matchEndTimeStamp).getTime();

  if (!matchStartTime || !matchEndTime || matchEndTime <= matchStartTime) {
    console.error('Invalid match timestamps:', {
      matchStartTime,
      matchEndTime,
    });

    return;
  }

  const elapsedMatchTimeInSeconds = (matchEndTime - matchStartTime) / 1000;
  let timeDifferenceInSeconds = (eventTime - matchStartTime) / 1000;
  const durationOffset = totalDuration - elapsedMatchTimeInSeconds;

  if (durationOffset > 0) {
    timeDifferenceInSeconds -= durationOffset;
  } else {
    timeDifferenceInSeconds += durationOffset;
  }

  if (timeDifferenceInSeconds < 0 || isNaN(timeDifferenceInSeconds)) {
    console.error('Calculated seek time is invalid:', timeDifferenceInSeconds);

    return;
  }

  try {
    await player?.current.seek(timeDifferenceInSeconds);
    console.info(`Player seeked to: ${timeDifferenceInSeconds} seconds.`);
  } catch (error) {
    console.error('Error during seek:', error);
  }
};

/**
 * Rail List Title Black List
 */
export const railTitleBlackList = ['16x9_spotlight_tray', 'slider', 'episode'];

/**
 * Color Type to Theme
 */
export const colorType2Theme = {
  live: 'destructive',
  active: 'primaryBlack',
  passive: 'transparent',
  'outline-active': 'black56',
  'outline-passive': 'white24',
  normal: 'neutral500',
};

/**
 * Get Dynamic Rail Initial Data
 *
 * @param {string} numberOfCardsOnRail - numberOfCardsOnRail
 * @returns {object} - width height object
 */
export const dynamicRailInitialData = (numberOfCardsOnRail) => {
  const initialData = new Array(numberOfCardsOnRail).fill({});

  return initialData;
};

/**
 * Update Page Size In Url
 *
 * @param {string} url - url
 * @param {number} newPageSize - newPageSize
 * @returns {string} - updated url
 */
export const updatePageSizeInUrl = (url, newPageSize) => {
  try {
    const urlObj = new URL(url);

    urlObj.searchParams.set('pageSize', newPageSize);

    return urlObj.toString();
  } catch (error) {
    console.error('URL güncellenirken hata oluştu:', error);

    return url;
  }
};

/**
 * Debounce
 *
 * @param {Function} func - trigger function
 * @param {number} delay - timeout delay
 * @returns {Function} - function
 */
export const debounce = (func, delay = 600) => {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, delay);
  };
};
