import 'resize-observer-polyfill';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import 'core-js';

if (typeof ResizeObserver === 'undefined') {
  window.ResizeObserver = require('resize-observer-polyfill').default;
}

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Platform, AppRegistry } from 'react-native';

import Root from 'shared/src/setup';

import { loadFontsForWeb } from '../../../util/webFontLoader';

loadFontsForWeb();

// AppRegistry.registerComponent('beONETV', () => Root);
// AppRegistry.runApplication('beONETV', {
//   rootTag: document.getElementById('root'),
// });

AppRegistry.registerComponent('beONETV', () => Root);

if (Platform.OS === 'web') {
  const rootTag = document.getElementById('root');

  if (!rootTag) {
    throw new Error('Root element not found.');
  }

  const RootComponent = Root;
  const rootElement = createRoot(rootTag);

  rootElement.render(<RootComponent />);
}
