import React from 'react';
import './tools/wdyr';
import { LogBox } from 'react-native';
import setupNavigation from './navigation/setup';
import GlobalContextProvider from './context';
import '../../../util/language/i18n';

LogBox.ignoreLogs(['Warning: ...']);
LogBox.ignoreAllLogs();
// eslint-disable-next-line no-console
console.disableYellowBox = true;

/**
 * This function is start point of project
 *
 * @param {module:App} App - App
 * @returns {module:JSX.Element} - JSX.Element
 */
function setupApp(App) {
  return () => (
    <GlobalContextProvider>
      <App />
    </GlobalContextProvider>
  );
}

/**
 * Setup Application
 */
const Root = setupApp(setupNavigation());

export default Root;
