const environment = {
  name: 'production',
  hasFMTSVod: false,
  hasFMTSLive: false,
  hasFMTSLiveToVod: false,
  isAutoUpdateEnabled: true,
  baseUrls: {
    test: {
      api: 'https://navigation.tod2-test.beiniz.biz/',
      image: 'https://tod-dev.enhance.diagnal.com/',
      apiVersion: '1',
    },
    regression: {
      api: 'https://navigation.tod2-reg.beiniz.biz/',
      image: 'https://tod-preprod.enhance.diagnal.com/',
      apiVersion: '1',
    },
    live: {
      api: 'https://navigation.tod2.beiniz.biz/',
      image: 'https://tod.enhance.diagnal.com/',
      apiVersion: '1',
    },
  },
  conviva: {
    appId: '',
    customerKey: '',
  },
};

export default environment;
