import React from 'react';

import { AppProvider } from './App/context';
import { MenuProvider } from './Menu/context';
import { AuthProvider } from './Auth/context';
import { ProfileProvider } from './Profile/context';
import { DeviceProvider } from './Device/context';
import { GlobalFocusProvider } from './GlobalFocus/context';

/**
 * Global Context Provider
 *
 * @param {object} props - props
 * @param {object} props.children - children
 * @returns {module:JSX.Element} - JSX.Element
 */
const GlobalContextProvider = ({ children }) => {
  return (
    <AppProvider>
      <DeviceProvider>
        <MenuProvider>
          <AuthProvider>
            <ProfileProvider>
              <GlobalFocusProvider>{children}</GlobalFocusProvider>
            </ProfileProvider>
          </AuthProvider>
        </MenuProvider>
      </DeviceProvider>
    </AppProvider>
  );
};

export default GlobalContextProvider;
