export const HTTP_URL = 'http://';
export const HTTPS_URL = 'https://';

// Api Modes
export const apiModes = {
  test: 'test',
  regression: 'regression',
  live: 'live',
};

export const collectionRailList = [
  '16x9_tray_branded_background',
  '2x3_poster_tray_branded',
  '4x3_branded_background_tray',
  '1x1_square_tray_branded',
];

export const topTenRailList = [
  '16x9_top10_tray_v1',
  '16x9_top10_tray_v2',
  '2x3_top_ten_tray',
];

export const certificateUrlForFairPlay =
  'https://digiturk.live.ott.irdeto.com/licenseServer/streaming/v1/digiturk/getcertificate?applicationId=app1';

const STREAM_FORMATS = {
  undefined: 0,
  DASH: 1,
  SS: 2,
  HLS: 3,
  MP4: 4,
};

export { STREAM_FORMATS };
